exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-exhibitors-tsx": () => import("./../../../src/pages/exhibitors.tsx" /* webpackChunkName: "component---src-pages-exhibitors-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-innovationchallenge-tsx": () => import("./../../../src/pages/innovationchallenge.tsx" /* webpackChunkName: "component---src-pages-innovationchallenge-tsx" */),
  "component---src-pages-novelties-tsx": () => import("./../../../src/pages/novelties.tsx" /* webpackChunkName: "component---src-pages-novelties-tsx" */),
  "component---src-pages-registrations-[type]-tsx": () => import("./../../../src/pages/registrations/[type].tsx" /* webpackChunkName: "component---src-pages-registrations-[type]-tsx" */),
  "component---src-pages-sponsors-tsx": () => import("./../../../src/pages/sponsors.tsx" /* webpackChunkName: "component---src-pages-sponsors-tsx" */),
  "component---src-pages-startups-tsx": () => import("./../../../src/pages/startups.tsx" /* webpackChunkName: "component---src-pages-startups-tsx" */)
}

